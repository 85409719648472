import COUNTRIES from 'oo-countrynames/countries.json';

export * from './categories';
export * from './platen';
export * from './api';
export * from './templates';
export * from './settings';
export * from './artwork';
export * from './usStatesByCode';
export * from './shopify';
export * from './standalone';
export * from './thirdPartyServices';
export * from './misc';

export enum PLATFORM_ENUM {
  FUEL = 'fuel',
  SHOPIFY = 'shopify',
}

export enum SHIPPING_METHOD_ENUM {
  EXPEDITED = 'expedited',
  STANDARD = 'standard',
}

export enum PLAN_NAME_ENUM {
  TRIAL = 'trial',
  BASIC = 'basic',
  AFFILIATE = 'affiliate',
}

export enum FEATURES {
  NECK_LABEL = 'Branded Neck Label',
  CUSTOM_RETURN = 'Custom Return Address',
  COLOR_CHANGING = 'Color Changing',
}

export enum TIERS {
  PROMOTION = 'PromotionTierV0',
  BASE = 'BaseTier',
  BETA = 'BetaTier',
  POWER = 'PowerSellerTier',
  DEV = 'DevTier',
  ADVANCE = 'AdvancedSeller',
}

export const tiersName: Record<TIERS, string> = {
  [TIERS.PROMOTION]: 'Promotion Tier',
  [TIERS.BASE]: 'Base Tier',
  [TIERS.BETA]: 'Beta Tier',
  [TIERS.POWER]: 'Power Tier',
  [TIERS.DEV]: 'Dev Tier',
  [TIERS.ADVANCE]: 'Advance Tier',
};

// This is the quantity of colors the palette will show in ProductItem -> catalog product card
export const NUMBER_COLORS_SHOW_COLPASE = 10;
export const COLOR_PALETTE_MAX = 12;

export const BUILD_NAME = import.meta.env.VITE_BUILD_NAME ?? 'N/A';

const US_COUNTRY = COUNTRIES.find(({ code }) => code == 'US')!;
export const CAN_EXPEDITE_SHIPPING_COUNTRIES = [US_COUNTRY.code];
